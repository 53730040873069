body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f0f1f6 !important;
    color: #454545;
    word-break: break-word;
}

button{ 
    outline: none !important; 
    word-break: normal;
}
a { color: #02a897;  }
a:hover{ color: #029687; }

.page-title{
    font-weight: 300;
    margin: 0.5rem 0 1.5rem;
    font-size: 2.2rem;
}

.page-sub-title{
    font-weight: 300;
    margin: 1.5rem 1rem 1.5rem;
    font-size: 1.5rem;
}

.card-group{
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
}

.card{
    border-radius: .15rem;
    /* border: none; */
    border-color: rgba(14, 32, 98, 0.12);
}

.card-header{
    border: none;
}

.simple-card{
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
}

.transp-card{
    background: rgba(255, 255, 255, 0.85);
}

.large-card-title{
    font-weight: 300;
    margin: 0 0 1.5rem;
    font-size: 2.2rem;
}

.bg-container{
    height: 100%;
    width: 100%;
    min-width: 100vw;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: url(Images/homepage-background.jpg);
    background-size: cover !important;
    z-index: -1;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-container{
    padding: 1rem 0 5rem;
}

.card-large-title{
    font-weight: 300;
    margin: 0 0 1.5rem;
    color: #0f3254;
    font-size: 2.2rem;
}

.items-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}
@media only screen and (max-width: 991px){
    .items-grid{ grid-template-columns: 1fr; }
}

/* .btn, .form-control{ */
.rounded-item {
    border-radius: 1.15rem;
}

.flex-1{ flex: 1; }

.text-sec1{ color: #858585 }
.text-sec2{ color: #a5a5a5 }

.modal-content{ 
    border: none;
    border-radius: .15rem;
}
.modal-footer{ 
    border: none;
    background: #f0f1f6; 
}
.modal-title {
    font-weight: 300;
    font-size: 1.7rem;
}

.btn-ic{
    font-size: 1.3rem;
    padding: 0;
    min-width: 36px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #858585;
}
.btn-ic:hover{
    background: #f0f1f6;
    color: #029687;
}
.btn-primary{
    background: #029687 !important;
    border-color: #029687 !important;
}
.btn-primary:hover {
    background: #02a897 !important;
    border-color: #02a897 !important;
}
.btn-outline-primary{
    color: #029687;
    border-color: #029687;
}
.btn-outline-primary:hover{
    background: #029687;
    border-color: #029687;
}
.btn-primary:focus, 
.btn-primary:active:focus, 
.btn-primary:not(:disabled):not(.disabled):focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:focus, 
.btn-outline-primary:active:focus, 
.btn-outline-primary:not(:disabled):not(.disabled):focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-ic:focus, 
.btn-ic:active:focus, 
.btn-ic:not(:disabled):not(.disabled):focus,
.btn-ic:not(:disabled):not(.disabled):active:focus{
    box-shadow: 0 0 0 0.2rem rgba(39, 168, 153, .5);
}

.btn-primary:active, 
.btn-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:active, 
.btn-outline-primary:not(:disabled):not(.disabled):active{
    background: #008073;
    border-color: #008073;
}

.btn-primary:disabled, .btn-primary.disabled{
    background: #6c757d;
    border-color: #6c757d;
}

.btn-link { color: #02a897; }
.btn-link:hover{ color: #029687; }

.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 168, 153, .5);
    border-color: #65cfc4;
}

.dropdown-item.active, .dropdown-item:active { background: #029687; }

.cmp-alert{ 
    border-radius: .15rem; 
    font-size: .9rem;
    border-radius: .35rem;
    padding: .8rem 3rem .8rem 1rem;
    border: none;
    color: #fff;
}
.alert-primary{ background: #029687; }
.alert-danger{ background: #dc3545; }

.progress-bar { background-color: #029687 }

.cmp-alert .btn-close{
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #fff;
    position: absolute;
    top: .25rem;
    right: .8rem;
    opacity: .8;
}
.cmp-alert .btn-close:hover{ opacity: 1; }

.react-toggle-track { background: #6c757d; }
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track { background: #5a6268; }
.react-toggle--checked .react-toggle-track{ background: #029687; }
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track { background: #02a897; }
.react-toggle--checked .react-toggle-thumb{ border-color: #029687; }
.react-toggle--focus .react-toggle-thumb,
.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb
{ box-shadow: 0 0 0 0.2rem rgba(39, 168, 153, .5); }


.content{
    min-height: 100vh;
    display:flex;
    flex-direction:column;
} 

.nav-and-switch{
    flex: 1;
}

.icon-color{
    color:#029687; 
}